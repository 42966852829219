#header {
    display: flex;
    flex-direction: column;
    background-color:#4acaa8;
    width:20vw;
    height: 100%;
    text-align: center;
    color: #d2f2e9;
    justify-content: space-between;
    position: fixed;
    font-family: 'Outfit', sans-serif;
}
#header p {
    color: #d2f2e9;
    font-family: 'Outfit', sans-serif;
    font-weight:450;
}
#header a:hover {
    color: #ffffff !important;
}
#header h1, #header h2, #header h3, #header h4, #header h5, #header h6 {
    color: #ffffff !important;
    font-family: 'Outfit', sans-serif;
}
.navbar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-right: 1vw;
}
nav a {
    color: #ffffff !important;
    text-decoration: none;
    font-family: 'Outfit', sans-serif;
    font-size: clamp(5px,15px, 35px);
}
#logo a {
    text-decoration: none;
    color: #ffffff;
}
.container-lg {
    display: flex;
    flex-direction: row;
    margin: 0;
}
nav ul li:hover {
    background-color: #b7eadc;
}
li {
    list-style-type: none;
}
ul.icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
}
.icons li a {
    color: #b7eadc;
    text-decoration: none;
    font-size: 25px;
}

header .avatar img {
    margin-top: 5vh;
    max-width: 15vw;
    max-height: 30vh;
}
