.card {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 100%;
    background-color: #fbf7f5;
}
.img-fluid {
   width: 100%;
   justify-content: center;
   margin-top: 2vh;
}
.card-title {
    color:#4acaa8;
    font-size: x-large;
}
.card-link {
    justify-content: space-between;
    text-decoration: none;
    color:#014f86;
    font-weight: bold;
    font-size: 16px;
}